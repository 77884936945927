.active-listings-page-2 {

    /* PROPERTY LISTINGS */
    .property-listings {
        padding: 15px 0px;

        .search-location-box {
            position: relative;

            .search-dropdown {
                padding: 10px 15px;

                h4 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    color: #323232;
                    margin-left: 10px;
                }
            }

            .search-box {
                position: absolute;
                top: 0;
                right: 0;
                background-color: #A08766;
                border: 2px solid #A08766;
                height: 100%;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
        
        .price-filters {

            .react-select.sort-control {
                .custom-select__control {
                    padding-left: 0px !important;
                }
            }

            .custom-select__control {
                padding-left: 15px;
            }

            .filters-btn {
                width: 100%;
                height: 100%;
                background-color: #E5E5E5;
                border: 2px solid #E5E5E5;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    font-size: 14px;
                    margin-left: 5px;
                    color: #323232;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
            }

            .filters-btn:hover {
                background-color: #FFF;
            }
        }


        .section-header {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 10px 15px;
            margin-bottom: 10px;

            p {
                font-size: 14px;
                color: grey;
                margin-bottom: 0px;
                font-weight: 400;

                span {
                    font-weight: 700;
                    color: #323232;
                }
            }

            .custom-select__control {
                width: 150px;
                border: 1px solid #E5E5E5;
            }

            .view-switcher {
                background-color: transparent;
                border: 0px solid transparent;
            }

            .view-switcher:hover {
                background-color: #E5E5E5;
            }

            .view-switcher.list-view {
                margin-right: 10px;
            }

            .view-switcher.grid-view {
                margin-right: 5px;
            }

            .view-switcher.active {
                background-color: #FFF;
                border: 1px solid #E5E5E5;
            }

            .filters-btn {
                margin-left: 10px;
                height: 100%;
                background-color: #FFF;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 5px;
                    color: #323232;
                    font-weight: 600;
                }
            }

            .switcher-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    margin-right: 15px;
                    margin-bottom: 0px;
                }
            }
        }

        .filters-applied {
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 15px;

            p {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-right: 10px;
            }

            .applied-filters-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .applied-filter {
                    display: flex;
                    align-items: center;
                    background-color: rgba($color: #A08766, $alpha: 0.2);
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-right: 5px;

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        color: #A08766;
                    }

                    .close-btn {
                        min-height: auto;
                        padding: 0px;
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }

        .filters-header {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            margin-top: 10px;

            .filters-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;

                .filters-btn {
                    height: 100%;
                    background-color: #FFF;
                    border: 1px solid #E5E5E5;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
            
                    p {
                        font-size: 14px;
                        color: #323232;
                        font-weight: 600;
                        margin-bottom: 0px;
                        text-transform: capitalize;
                    }
                }

                .property-number {
                    font-size: 14px;
                    color: grey;
                    margin-bottom: 0px;
                }

                .company-text {
                    font-size: 14px;
                    color: #323232;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                .apply-filters-btn {
                    background-color: #A08766;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: capitalize;
                    border-color: #A08766;
                }
            }

            .sub-heading {
                font-size: 15px;
                font-weight: 700;
                color: #323232;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }


        .section-body {
            height: calc(100vh - 250px);
            overflow: auto;
            overflow-x: hidden;
        }
        .blogs-pagination {
            margin-top: 20px;
            display: flex;
            align-items: center;
            list-style-type: none;
            justify-content: center;
      
            .previous,
            .next {
              background-color: #fff;
              border-radius: 50%;
              padding: 4px 10px;
              border: 1px solid #e5e5e5;
      
              a {
                text-decoration: none;
                color: #323232;
              }
            }
      
            .blog-pagination-btn {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              background-color: #FFF;
              border: 1px solid #E5E5E5;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px;
            }
      
            li {
              height: 35px;
              width: 35px;
              border-radius: 50%;
              border: 1px solid #e5e5e5;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              cursor: pointer;
              padding: 5px;
      
              a {
                font-size: 14px;
                font-weight: 600;
              }
            }
      
            li:hover {
              background-color: #A08766;
      
              a {
                color: #fff;
              }
            }
            .active {
              background-color: #A08766;
      
              a {
                color: #fff;
              }
            }
          }
        .section-body::-webkit-scrollbar {
            display: none;
        }
    }

    /* LISTINGS MAP */
    .listings {
        padding: 15px 0px;
        height: calc(100vh - 100px);

        #map {
            border-radius: 5px;
            width: 100%;
            height: calc(100vh - 100px);
        }

        .map-section {
            border-radius: 5px;
            width: 100%;
            height: calc(100vh - 110px);
        }
    }

    .listing-list {
        border: 1px solid #E5E5E5;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        margin-bottom: 15px;

        .listing-list-body {
            display: flex;
            align-items: center;

            .property-images {
                position: relative;

                .swiper {
                    width: 150px;
                }

                .img-slider {
                    width: 100% !important;
                }

                .property-img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 5px;
                }

                .swiper {
                    .swiper-button-next {
                        height: 20px !important;
                        width: 20px !important;
                        top: 55%;
                    }

                    .swiper-button-prev {
                        height: 20px !important;
                        width: 20px !important;
                        top: 55%;
                    }


                    .swiper-button-next:after,
                    .swiper-button-prev:after {
                        background-color: #FFF;
                        font-size: 8px !important;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 20px;
                        width: 20px;
                        font-weight: 700;
                        color: #000;
                    }
                }

                .images-number {
                    position: absolute;
                    top: 10px;
                    right: 55px;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    border-radius: 5px;
                    width: max-content;
                    padding: 5px 10px;
                    z-index: 100;
                    display: flex;
                    align-items: center;

                    p {
                        color: #FFF;
                        font-size: 14px;
                        margin-bottom: 0px;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }
            }

            .property-details {
                width: 100%;
                margin-left: 20px;

                .top-section {
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        color: #A08766;
                        margin-bottom: 10px;
                    }

                    h5 {
                        margin-bottom: 0px;
                        font-weight: 700;
                    }
                }

                .middle-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;

                    h5 {
                        font-size: 18px;
                        color: #323232;
                        font-weight: 700;
                        margin-bottom: 0px;
                    }

                    p {
                        margin-bottom: 0px;
                        color: grey;
                        font-weight: 500;
                        margin-top: 2px;
                        font-size: 14px;

                        .mls-no {
                            font-size: 16px;
                        }
                    }
                }

                .bottom-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #FFF;
                    margin-top: 15px;

                    p {
                        margin-bottom: 0px;
                        color: grey;
                        font-weight: 500;
                        font-size: 14px;

                        span {
                            font-size: 16px;
                            color: #323232;
                            font-weight: 500;
                        }
                    }

                    .other-details {
                        display: flex;
                        align-items: center;

                        .details-box {
                            display: flex;
                            align-items: center;
                            margin-left: 20px;

                            img {
                                width: 20px;
                                height: auto;
                            }

                            p {
                                margin-left: 10px;
                                margin-bottom: 0px;
                                font-size: 14px;
                                font-weight: 600;
                                color: #323232;
                            }
                        }

                        .details-box:first-child {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
    /* LIST VIEW */
.listing-list {
    border: 1px solid #E5E5E5;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 100%;

    .listing-list-body {
        display: flex;
        align-items: center;

        .property-images {
            position: relative;

            .swiper {
                width: 150px;
            }

            .favorites {
                width: 100%;
                position: absolute;
                top: -5px;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;

                .logo-badge {
                    background-color: #A08766;
                    padding: 5px 10px;
                    border-radius: 5px;
            
                    p {
                        font-size: 13px;
                        color: #FFF;
                        margin-bottom: 0px;
                        font-weight: 700;
                    }
                }
            }
    
            .img-slider {
                width: 100% !important;
            }
    
            .property-img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 5px;
            }
    
            .swiper {
                .swiper-button-next {
                    height: 20px !important;
                    width: 20px !important;
                    top: 55%;
                }
    
                .swiper-button-prev {
                    height: 20px !important;
                    width: 20px !important;
                    top: 55%;
                }
    
    
                .swiper-button-next:after,
                .swiper-button-prev:after {
                    background-color: #FFF;
                    font-size: 8px !important;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    font-weight: 700;
                    color: #000;
                }
            }
    
            .images-number {
                position: absolute;
                top: 10px;
                right: 55px;
                background-color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 5px;
                width: max-content;
                padding: 5px 10px;
                z-index: 100;
                display: flex;
                align-items: center;
    
                p {
                    color: #FFF;
                    font-size: 14px;
                    margin-bottom: 0px;
                    font-weight: 600;
                    margin-left: 5px;
                }

                .skeleton-loading {
                    height: 10px;
                }
            }
    
            .favorite-btn {
                position: absolute;
                top: 8px;
                right: 10px;
                background-color: #FFF;
                width: max-content;
                z-index: 100;
                display: flex;
                align-items: center;
                border-radius: 50%;
                padding: 0px;
                border: 1px solid #A08766;
                padding: 8px;
            }

            .favorite-btn:hover {
                background-color: #A08766;

                svg {
                    fill: #FFF;
                }
            }
        }

        .property-details {
            width: 100%;
            margin-left: 20px;

            .top-section {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .loading-skeleton {
                    width: 130px;
                    height: 20px;
                }

                .badge-loading-skeleton {
                    height: 25px;
                    width: 90px;
                    background-color: rgba($color: #A08766, $alpha: 0.1);
                }
        
                h2 {
                    font-size: 20px;
                    margin-bottom: 0px;
                    font-weight: 700;
                }
        
                .badge {
                    background-color: rgba($color: #A08766, $alpha: 0.1);
                    border-radius: 5px;
                    padding: 10px 10px;
        
                    p {
                        font-size: 12px;
                        color: #A08766;
                        margin-bottom: 0px;
                        font-weight: 600;
                    }
                }

                .logo-badge {
                    background-color: #A08766;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-right: 10px;
                    
                    p {
                        font-size: 13px;
                        color: #FFF;
                        margin-bottom: 0px;
                        font-weight: 700;
                    }
                }

                .images-number {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    border-radius: 5px;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    p {
                        color: #FFF;
                        font-size: 14px;
                        margin-bottom: 0px;
                        font-weight: 600;
                        margin-left: 5px;
                    }

                    .loading-skeleton {
                        width: 20px;
                        height: 15px;
                        margin-left: 5px;
                    }
                }

                .favorite-btn {
                    background-color: #FFF;
                    width: max-content;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    padding: 0px;
                    border: 1px solid #A08766;
                    padding: 8px;
                    margin-left: 10px;
                }

                .favorite-btn:hover {
                    background-color: #A08766;

                    svg {
                        fill: #FFF;
                    }
                }
            }

            .middle-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;

                .street-loading-skeleton {
                    width: 150px;
                    height: 20px;
                }

                .city-loading-skeleton {
                    width: 120px;
                    height: 15px;
                    margin-top: 5px;
                }

                h2 {
                    font-size: 16px;
                    color: #323232;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    color: grey;
                    font-weight: 400;
                    margin-top: 2px;
                    font-size: 14px;

                    .mls-no {
                        font-size: 16px;
                    }
                }
            }

            .bottom-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #FFF;
                margin-top: 15px;

                .size-loading-skeleton {
                    width: 80px;
                    height: 20px;
                }

                .lot-loading-skeleton {
                    width: 100px;
                    height: 20px;
                    margin-left: 20px;
                }
            
                p {
                    margin-bottom: 0px;
                    color: grey;
                    font-weight: 400;
                    font-size: 14px;
            
                    span {
                        font-size: 16px;
                        color: #323232;
                        font-weight: 600;
                    }
                }
            
                .other-details {
                    display: flex;
                    align-items: center;

                    .bed-loading-skeleton {
                        width: 25px;
                        height: 15px;
                        margin-left: 5px;
                    }
            
                    .details-box {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;
            
                        img {
                            width: 20px;
                            height: auto;
                        }
            
                        p {
                            margin-left: 10px;
                            margin-bottom: 0px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #323232;
                        }
                    }
                }
            }
        }
    }
}
}