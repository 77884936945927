@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600;700;800;900&display=swap');

/* PACKAGE STYLES */
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'react-loading-skeleton/dist/skeleton.css';

/* LAYOUT STYLES */
@import './layout/header.scss';
@import './layout/footer.scss';

/* PAGE STYLES */
@import './pages/homepage.scss';
@import './pages/buying/index.scss';
@import './pages/selling/index.scss';
@import './pages/contact-us/index.scss';
@import './pages/blogs/index.scss';
@import './pages/listings/active-listings.scss';
@import './pages/listings/property-detail.scss';
@import './pages/home-evaluation/index.scss';

/* RESPONSIVE STYLES */
@import './responsive.scss';

body {
    font-family: 'Figtree', sans-serif;
    overflow-x: hidden;
    margin: auto;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.h-100 {
    height: 100% !important;
}

.p-relative {
    position: relative !important;
}

.form-group {
    margin-bottom: 15px;

    .dollar-sign {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #323232;
        margin-bottom: 0px;
    }

    .form-label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;

        span {
            color: red;
        }
    }

    .form-control {
        width: 100%;
        border: 2px solid #E5E5E5;
        font-size: 14px;
        color: #323232;
        padding: 8px 15px;
    }

    .textarea-control {
        width: 100%;
        display: block;
        border: 2px solid #E5E5E5;
        font-size: 14px;
        color: #323232;
        padding: 8px 15px;
        border-radius: 5px;
    }

    .textarea-control.validation-border {
        border: 2px solid red;
    }

    .form-control.validation-border {
        border: 2px solid red;
    }

    .only-field {
        margin-top: 28px;
    }

    .calculator-input {
        padding-left: 30px;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .form-control::placeholder {
        font-size: 13px;
        font-weight: 400;
    }

    .start-dollar {
        position: absolute;
        top: 10px;
        left: 12px;
        color: #323232;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .end-percent {
        position: absolute;
        top: 10px;
        right: 12px;
        color: #323232;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .status-checkbox {
        min-height: auto;
        margin-bottom: 10px;

        .form-check-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 13px;
            margin-top: 5px;
            white-space: nowrap;
        }

        .form-check-input {
            height: 20px;
            width: 20px;
            border: 2px solid #E5E5E5;
        }

        .form-check-input:focus {
            box-shadow: none;
        }

        .form-check-input:checked {
            box-shadow: none;
        }
    }

    .status-radio {
        min-height: auto;
        margin-left: 20px;

        .form-check-label {
            margin-left: 5px;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
        }

        .form-check-input {
            height: 18px;
            width: 18px;
            border: 2px solid #E5E5E5;
        }

        .form-check-input:focus {
            box-shadow: none;
        }

        .form-check-input:checked {
            box-shadow: none;
        }
    }

    .options-radio {
        margin-top: 5px;
    }

    .status-radio:first-child {
        margin-left: 0px;
    }
}

.logo-box {
    img {
        width: 180px;
        height: auto;
    }
}

.main-menu {
    .menu-link {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.phone-number-select .PhoneInputCountry {
    border: 2px solid #E2E9E1;
    border-radius: 4px;
    padding: 15px;
    height: 40px;
    background-color: #FFF;
}

.phone-number-select.validation-border .PhoneInputCountry {
    border: 2px solid red;
}

.phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
    color: #465B52;
    font-size: 15px;
    margin-bottom: 10px;
}

.phone-number-select .PhoneInputInput {
    border: 2px solid #E2E9E1;
    border-radius: 4px;
    height: 40px;
    box-shadow: none;
    color: #161C27;
    font-size: 14px;
    padding: 6px 12px 6px 20px;
    width: 100%;
    font-weight: 500;
}

.phone-number-select.validation-border .PhoneInputInput {
    border: 2px solid red;
}

.phone-number-select .PhoneInputInput::placeholder {
    font-size: 12px;
    font-weight: 400;
}

.react-select {
    .custom-select__control {
        border: 2px solid #E5E5E5;

        .custom-select__value-container {
            .custom-select__placeholder {
                font-size: 13px;
                font-weight: 400;
                color: grey;
            }

            .custom-select__single-value {
                font-weight: 500;
                font-size: 13px;
                color: #323232;
            }
        }

        .custom-select__indicators {
            .custom-select__indicator-separator {
                display: none;
            }

            .custom-select__indicator {
                padding-left: 0px;

                svg {
                    fill: #323232;
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .custom-select__multi-value {
            background-color: #E5E5E5;
            padding: 3px 8px;
            border: 1px solid #E5E5E5;
            border-radius: 20px;
            font-size: 15px;
            font-weight: 600;

            .custom-select__multi-value__remove:hover {
                background-color: transparent;

                svg {
                    fill: #A08766;
                }
            }
        }
    }

    .custom-select__control:hover {
        border: 2px solid #E5E5E5;
    }

    .custom-select__control.validation-border {
        border: 2px solid red;
    }

    .custom-select__control--is-focused {
        border: 2px solid #E5E5E5;
        box-shadow: none;
    }

    .custom-select__menu {
        z-index: 10;

        .custom-select__menu-list {
            padding: 5px;

            .custom-select__option {
                font-size: 13px;
                font-weight: 500;
                border-radius: 5px;
                cursor: pointer;
            }

            .custom-select__menuu-notice {
                font-size: 14px;
                font-weight: 600;
            }

            .custom-select__option--is-selected {
                background-color: #FFF;
                color: #323232;
            }

            .custom-select__option--is-focused {
                background-color: transparent;
                color: #323232;
            }

            .custom-select__option:hover {
                background-color: rgba($color: #A08766, $alpha: 0.1);
                color: #A08766;
            }
        }
    }

    .custom-select-two__control {
        width: 150px;
        border: 2px solid #E5E5E5;

        .custom-select-two__value-container {
            .custom-select-two__placeholder {
                font-size: 14px;
                font-weight: 500;
                color: #323232;
            }

            .custom-select-two__single-value {
                font-weight: 500;
                font-size: 14px;
                color: #323232;
            }
        }

        .custom-select-two__indicators {
            .custom-select-two__indicator-separator {
                display: none;
            }

            .custom-select-two__indicator {
                padding-left: 0px;

                svg {
                    fill: #323232;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .custom-select-two__control--is-focused {
        border: 2px solid #E5E5E5;
        box-shadow: none;
    }

    .custom-select-two__menu {
        .custom-select-two__menu-list {
            .custom-select-two__option {
                font-size: 13px;
                font-weight: 500;
            }

            .custom-select-two__option--is-focused {
                background-color: rgba($color: #A08766, $alpha: 0.1);
            }
        }
    }
}

.react-select.validation-border {
    .custom-select__control {
        border: 2px solid red;
    }
}

.map-marker-custom {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dark-border {
        height: 20px;
        width: 20px;
        background-color: #D09E80;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .white-border {
            height: 10px;
            width: 10px;
            background-color: #FFF;
            border-radius: 50%;
        }
    }
}

.map-pin {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 2px solid #A08766;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -23px;
    margin-top: -20px;

    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.locate-reset-btn {
    background-color: white;
    border: 0px solid #FFF;
    color: #000;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 13px;
    font-weight: 600;
}

.locate-reset-btn:hover {
    color: #A08766;
    background-color: transparent;
}

.combobbox-popover-styles {
    z-index: 100 !important;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;

    ul {
        li {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}

.no-properties-found-box {
    margin-top: 10px;
    background-color: #F5F5F5;
    padding: 20px;
    border-radius: 5px;

    h4 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0px;
        color: #323232;
    }

    p {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0px;
    }
}

.property-search-box {
    border: 2px solid #E5E5E5;
    font-size: 14px;
    color: #323232;
    padding: 8px 15px;
}

.Toastifytoast {
    .Toastifytoast-body {
        font-size: 15px;
        font-weight: 600;
        color: #323232;
        font-family: 'Figtree', sans-serif !important;
    }
}

.navbar-toggler {
    background-color: #FFF;
}

.heading-loader {
    height: 50px;
}

.heading-sm-height-loader {
    height: 30px;
}

.popover-property-details {
    display: flex;
    align-items: center;

    .property-img {
        img {
            height: 70px;
            width: 70px;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .property-details {
        margin-left: 10px;

        h5 {
            font-family: 'Figtree', sans-serif !important;
            font-size: 14px;
            font-weight: 700;
            color: #323232;
            margin-bottom: 0px;
        }

        .other-details {
            margin-top: 8px;

            p {
                font-family: 'Figtree', sans-serif;
                font-size: 13px;
                font-weight: 700;
                color: #555;
                margin-bottom: 0px;
            }

            .bed-bath-details {
                display: flex;
                align-items: center;
                margin-top: 5px;

                .bedbath {
                    font-family: 'Figtree', sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    color: #555;
                    margin-bottom: 0px;
                    border-right: 1px solid grey;
                    padding-right: 5px;
                }

                .bedbath:last-child {
                    padding-left: 5px;
                    border-right: 0px solid transparent;
                }
            }
        }
    }
}