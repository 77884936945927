 .header {
     background-color: rgba($color: #000000, $alpha: 0.7);

     .top-header {
         border-bottom: 1px solid rgba($color: #CECECE, $alpha: 0.3);

         .header-links {
             display: flex;
             align-items: center;
             justify-content: space-between;

             .left-section {
                 display: flex;
                 align-items: center;

                 .social-media-box {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                    border-right: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                    padding: 8px 12px;
                    text-decoration: none;
                    color: #FFF;

                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }
                 }

                 .social-media-box:first-child {
                     border-left: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                 }

                 .social-media-box:hover {
                     background-color: #FFF;

                     svg {
                         fill: #323232;
                     }

                     p {
                        color: #323232;
                     }
                 }
             }

             .right-section {
                 display: flex;
                 align-items: center;

                 .social-media-box {
                     display: flex;
                     align-items: center;
                     border-left: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                     border-right: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                     padding: 8px 12px;
                     text-decoration: none;
                     color: #FFF;

                     p {
                         font-size: 14px;
                         font-weight: 600;
                         margin-bottom: 0px;
                         margin-left: 10px;
                     }
                 }

                 .social-media-box:last-child {
                     padding-right: 0px;
                     border-right: 0px solid transparent;
                 }
             }
         }
     }

     .header-wrap {
         display: flex;
         align-items: center;
         justify-content: space-between;
         padding-top: 10px;
         padding-bottom: 10px;
         padding-left: 0px;
         padding-right: 0px;

         .header-links {
             display: flex;
             align-items: center;
             justify-content: space-between;

             .logo-box {
                 background-color: #FFF;
                 border-radius: 5px;
                 padding: 5px 10px;

                .company-logo {
                    width: 200px;
                    height: auto;
                    // padding-right: 20px;
                }
            
                .agent-logo {
                    width: 100px;
                    height: auto;
                    border-left: 2px solid #E5E5E5;
                    padding-left: 20px;
                }
             }
         }

         .navigation-links {
             .logo-box {
                 img {
                     width: 180px;
                     height: auto;
                 }
             }

             .navbar {
                 padding: 0px;
             }

             .main-menu {

                 .menu-link {
                     font-size: 14px;
                     color: #FFF;
                     font-weight: 600;
                     padding: 0px 30px;
                     text-transform: uppercase;
                 }

                 .menu-link:last-child {
                     padding-right: 0px;
                 }

                 .menu-link:first-child {
                     padding-left: 0px;
                 }
             }
         }
     }
 }

  .header.dark {
      background-color: rgba($color: #FFF, $alpha: 0.7);

      .top-header {
          border-bottom: 1px solid #E5E5E5;

          .header-links {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left-section {
                  display: flex;
                  align-items: center;

                .social-media-box {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                    border-right: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                    padding: 8px 12px;
                    text-decoration: none;
                    color: #323232;
            
                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 0px;
                        margin-left: 10px;
                    }

                    svg {
                        fill: #323232;
                    }
                }
            
                .social-media-box:first-child {
                    border-left: 1px solid rgba($color: #CECECE, $alpha: 0.3);
                }
                
                .social-media-box:hover {
                    background-color: #323232;
            
                    svg {
                        fill: #FFF;
                    }
            
                    p {
                        color: #FFF;
                    }
                }
              }

              .right-section {
                  display: flex;
                  align-items: center;

                  .social-media-box {
                      display: flex;
                      align-items: center;
                      border-left: 1px solid #E5E5E5;
                      border-right: 1px solid #E5E5E5;
                      padding: 8px 12px;
                      text-decoration: none;
                      color: #000;

                      svg {
                        fill: #000;
                      }

                      p {
                          font-size: 14px;
                          font-weight: 600;
                          margin-bottom: 0px;
                          margin-left: 10px;
                      }
                  }

                  .social-media-box:last-child {
                      padding-right: 0px;
                      border-right: 0px solid transparent;
                  }
              }
          }
      }

      .header-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 0px;
          padding-right: 0px;
          border-bottom: 1px solid #E5E5E5;

          .header-links {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .logo-box {
                  background-color: #FFF;
                  border-radius: 5px;
                  padding: 5px 10px;
                  display: flex;
                  align-items: center;

                .company-logo {
                    width: 200px;
                    height: auto;
                    // padding-right: 20px;
                }
                
                .agent-logo {
                    width: 100px;
                    height: auto;
                    border-left: 2px solid #E5E5E5;
                    padding-left: 20px;
                }
              }
          }

          .navigation-links {
              .logo-box {
                  img {
                      width: 180px;
                      height: auto;
                  }
              }

              .navbar {
                  padding: 0px;
              }

              .main-menu {

                  .menu-link {
                      font-size: 14px;
                      color: #000;
                      font-weight: 600;
                      padding: 0px 30px;
                      text-transform: uppercase;
                  }

                  .menu-link:last-child {
                      padding-right: 0px;
                  }

                  .menu-link:first-child {
                      padding-left: 0px;
                  }
              }
          }
      }
  }