.home-evaluation-page {
    background: url('../../../assets/home-evaluation-page-banner.webp');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;

    .form-card {
        .form-cardbody {
            padding: 50px 40px;

            h1 {
                font-size: 25px;
                font-weight: 700;
                color: #000;
                margin-bottom: 0px;
                text-transform: uppercase;
                text-align: center;
                line-height: 35px;
            }

            h5 {
                font-size: 16px;
                font-weight: 500;
                color: #000;
                margin-top: 25px;
                line-height: 24px;
                text-align: center;
            }

            .box-form {
                margin-top: 40px;

                .form-buttons {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 10px;
                }

                .back-btn {
                    background-color: #E5E5E5;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px 25px;
                    text-align: center;
                    border: 0px solid transparent;
                    color: #323232;

                    svg {
                        margin-right: 5px;
                    }
                }

                .get-started-btn {
                    background-color: #A08766;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px 25px;
                    text-align: center;
                    border: 0px solid transparent;
                }
            }

            .thank-you-page {
                text-align: center;

                .agent-profile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;

                    .profile-img {
                        height: 100px;
                        width: 100px;
                        background-color: #A08766;
                        border-radius: 50%;
                        padding: 2px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .profile-details {
                        margin-left: 15px;

                        h5 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0px;
                            color: #000;
                            text-align: left;
                            margin-top: 0px;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 0px;
                            text-align: left;
                            color: #000;

                            a {
                                text-decoration: none;
                                color: #A08766;
                                font-weight: 600;
                            }
                        }

                        p:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }

                .address {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    margin-bottom: 0px;
                    margin-top: 10px;
                }

                .details {
                    margin-top: 5px;

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 5px;
                        color: #000;

                        a {
                            text-decoration: none;
                            color: #A08766;
                            font-weight: 600;
                        }
                    }

                    p:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}